<template>
    <div class="container-menu" :style="'overflow:hidden; overflow-y:auto; height: calc(100vh - 95px )'">
        <Menu v-if="this.menus && this.menus[0] && this.menus[0].menus && this.menus[0].menus.length > 1"/>
        <div id="app-content" :style="appStyle" >
            <SubHeader class="subHeader" @resendToNonOpeners="resendToNonOpeners" @loggedAs="loggedAs" @noSubMenu="noSubMenu" @exportRdvVendors="exportRdvVendors" @importDataDialog="importDataDialog" @reaffectVendorsAction="reaffectVendorsAction" @openHistoryDrawer="openHistoryDrawer" @openOpeDetails="openOpeDetails" @openReaffectDialog="openReaffectDialog" @openAddDialogForm="openAddDialogForm" @openBlacklistForm="openBlacklistForm" @setUserIndispo="setUserIndispo" @form="openForm" @refreshData="customRefresh" @editOpeDate="openOpeDateChangeForm" @programRp="openProgramRpDialog" @sendReport="openSendReportDialog" @actionMenus="actionForm" @openLibraryDialog="openLibraryDialog" @openOpeCloseForm="openOpeCloseForm" />
            <div :style="'overflow:hidden; overflow-y:auto; '+styleSubMenu" id="containerView">
                <v-fade-transition mode="out-in">
                    <router-view :key="$route.path"/>
                </v-fade-transition>
                <LoaderOpaque v-if="$store.state.loaderDashboard"/>
            </div>
            <v-dialog persistent width="400" v-model="showDownload">
                <v-card>
                    <v-card-text class="pa-6">
                        <h5 class="icon-title">
                        {{$t('downloadReady')}}
                        <v-btn @click="showDownload = false" text class="square">
                            <v-icon small>$close</v-icon>
                        </v-btn>
                        </h5>
                        <div class="text-center mt-4 mb-2">
                        <a :href="downloadUrl" @click="" download class="exportBtn">{{$t('clickForDownload')}}</a>
                        </div>
                        
                    </v-card-text>
                </v-card>
            </v-dialog>
            <v-dialog v-model="entitySelectDialog" width="600">
            <v-card>
                <v-toolbar tile flat dark  class="bg-gradient" height="64" style="height:80px;">
                    <div style="display:flex; width:100%; height:64px; align-items:center; justify-content:space-between;">
                        <div class="d-flex align-center">
                            <v-toolbar-title class="d-flex align-center">{{$t("selectEntityForReaffectation")}}</v-toolbar-title>
                        </div>
                        <div>
                            <v-btn outlined dark depressed @click.native="entitySelectDialog = false">{{  $t('cancel') }}</v-btn>
                            <v-btn outlined dark depressed @click="reaffectVendors" class="ml-3">{{ $t('reaffect') }}</v-btn>
                        </div>
                    </div> 
                </v-toolbar>
                <v-card-text>
                    <div class="mt-3">{{$t('whichEntity')}}</div>
                    <v-select hide-details required solo flat class="input-form" v-model="entitySelected" :items="entitiesItems" ></v-select>
                    <p class="error--text">{{ errorEntity }}</p>
                </v-card-text>
            </v-card>
        </v-dialog>
            <FormDialogComponent
            v-if="form"
            ref="componentForm"
            :form="form"
            :action="'SET_DATA'" />
            <ActionMenusDialogComponent v-if="showActionMenusDialog" :items="itemsActionMenusDialog" ref="actionMenus" @actionForm="actionForm" @cancel="showActionMenusDialog = false;" />

            <LibraryDialogComponent v-if="formLib" :form="formLib" :libType="libParams.libType" @cancel="formLib = null;" :showLibraryDialog="showLibraryDialog" @hideLibraryDialog="showLibraryDialog = false;" :libParams="libParams" />
        </div>
        <importDataContactComponent @closeModal="closeModalImportData" :displayUpload="displayUploadData" :operationId="operation_id" :returnData="true" />
    </div>
</template>
<script>
import GenericDataService from '@/services/GenericDataService';
import Menu from '@/components/Menu'
import LoaderOpaque from '@/components/LoaderOpaque'
import GlobalHeader from '@/components/GlobalHeader'
import SubHeader from '@/components/SubHeader'
import getForm from '@/mixins/mixins';
import refreshData from '@/mixins/mixins';
import FormDialogComponent from '@/components/dialogs/FormDialogComponent'
import ActionMenusDialogComponent from '@/components/dialogs/ActionMenusDialogComponent'
import LibraryDialogComponent from "@/components/dialogs/LibraryDialogComponent";
import importDataContactComponent from '@/components/importDataContactComponent.vue';

export default {
    name:"BaseMenu",
    components:{Menu, GlobalHeader, SubHeader, LoaderOpaque, FormDialogComponent, ActionMenusDialogComponent, LibraryDialogComponent, importDataContactComponent},
    mixins:[getForm, refreshData],
    data() {
        return {
            entitySelectDialog: false,
            entitySelected: null,
            entitiesItems : [],
            errorEntity: "",
            apiUrl:"",
            mutation: "",
            form:"",
            showActionMenusDialog: false,
            itemsActionMenusDialog: [],
            formLib: null,
            libParams: {},
            showLibraryDialog: false,
            operation_id: null,
            downloadUrl: "",
            api: process.env.VUE_APP_API_URL,
            showDownload: false,
            displayUploadData: false
        }
    },
    computed: {
        ratio: function(){
            return window.devicePixelRatio
        },
        menus: function(){
            return this.$store.state.base.menu
        },
        appStyle: function(){
            let widthMenu = 100
            if(this.menus && this.menus[0] && this.menus[0].menus && this.menus[0].menus.length == 1)
                widthMenu = 0;
            
            return "width: calc(100vw - "+widthMenu+"px );"
        },
        styleSubMenu: function() {
            if(this.hasSubMenu) {
                return  "height: calc(100vh - 245px );"
            } else if(this.$route.meta.title == 'home'){
                return  "height: calc(100vh - 175px );"
            } else {
                return  "height: calc(100vh - 205px );"
            }
        },
        hasSubMenu: function() {
            return this.$store.state.base.hasSubMenu
        }
       
    },
     methods: {
        reaffectVendors() { 
            if(!this.entitySelected){
                this.errorEntity = this.$t('selectEntityForReaffectation')
                return;
            } else {
                let url = '/contact/getReassignContactsForm?operation_id=' + this.operation_id + '&entity_id='+this.entitySelected;
                this.getForm(url, false, false, null, null, true, true);
                this.errorEntity = ''
                this.entitySelectDialog = false
            }
        },
        reaffectVendorsAction(){
            this.operation_id = this.$route.params.operation_id;
            let entities = this.$store.state.operation.operations['config.entity_id']
            
            if(entities && entities.length == 1){
                let url = '/contact/getReassignContactsForm?operation_id=' + this.operation_id + '&entity_id='+entities[0].id;
                this.getForm(url, false, false, null, null, true, true);
            } else {
                this.entitiesItems = entities.map((entity) => {
                    return {text: entity.label, value: entity.id}
                })
                this.entitySelectDialog = true;
            }
        },
        closeModalImportData(){
            this.displayUploadData = false;
        },
        importDataDialog($evt){
            this.operation_id = this.$route.params.operation_id;
            this.displayUploadData = true;
            console.log($evt, this.operation_id)
        },
        noSubMenu:function(){
            this.hasSubMenu = false;
        },
        exportRdvVendors:function($evt){
            this.$store.dispatch("SET_LOADER_DASHBOARD", true);
            GenericDataService.getData($evt.apiUrl).then((response) => {
                if(response.data.data.directDownload){
                    this.downloadUrl = this.api + response.data.data.directDownload
                    this.showDownload = true;
                }
                this.$store.dispatch("SET_LOADER_DASHBOARD", false);
            }).catch((e) =>{
                this.$store.dispatch("SET_LOADER_DASHBOARD", false);
            });
        },
        setUserIndispo : function(){
            this.$store.dispatch("agenda/SET_OPEN_INDISPO_USER", !this.$store.state.agenda.openIndispoDialog);
        },
        removeCookie(cname) {
            let expires = "expires=Thu, 01 Jan 1970 00:00:00 UTC";
            document.cookie = cname + "=; " + expires + "; path=/";
        },
        loggedAs: function($event) {
            this.$store.dispatch('settings/SET_PARAM', { key: 'loggedAs', data: this.$store.state.user.user }).then((e) => {
                this.removeCookie('role_id');
                let routeData = this.$router.resolve({ name: "Home" });
                window.location.href = routeData.href;
            });
        },
        openBlacklistForm() {
            this.$store.dispatch("contact/OPEN_BLACKLIST_FORM", true);
        },
        openAddDialogForm() {
            this.$store.dispatch("contact/OPEN_ADD_FORM", true);
        },
        openOpeDetails() {
            this.$store.dispatch("operation/SET_OPEDETAIL_POPUP", true);
        },
        openOpeCloseForm() {
            this.$store.dispatch("operation/SET_OPECLOSE_FORM", true);
        },
        openOpeDateChangeForm() {
            this.$store.dispatch("operation/SET_OPEDATECHANGE_FORM", true);
        },
        openProgramRpDialog() {
            this.$store.dispatch("campaign/SET_PROGRAM_RP_DIALOG", true);
        },
        resendToNonOpeners() {
            this.openLibraryDialog(null, {params:{libType: "EMAIL", campaign_id: this.$route.params.id, operation_id: this.$route.params.operation_id, sendToNonOpeners: true}});
        },
        openSendReportDialog() {
            this.$store.dispatch("operation/SET_SEND_REPORT_DIALOG", true);
        },
        openHistoryDrawer(data) {
            let params = this.$route.params;
            this.$store.dispatch("history/SET_OPEN_HISTORY_DRAWER", !this.$store.state.history.openHistoryDrawer);
            this.$store.dispatch("history/SET_API_URL", data.apiUrl.replace(/{{(.*?)}}/g, function(match, fieldName) { return params[fieldName]; }));
        },
        openReaffectDialog(data, test){
            this.$store.dispatch("campaign/SET_REAFFECT_VENDOR", test.objectId);
        },
        openForm(apiUrl, mutation, url, arrayIds, action = false){
            this.apiUrl = apiUrl;
            this.mutation = mutation;
            this.getForm(url, arrayIds, action);
        },
        customRefresh(apiUrl, mutation) {
            this.apiUrl = apiUrl;
            this.mutation = mutation;
            this.refreshData();
        },
        actionForm(item) {
            if(this.showActionMenusDialog) {
                this.itemsActionMenusDialog = [];
                this.showActionMenusDialog = false;
            }

            if(item.action == "actionMenus") {
                this.itemsActionMenusDialog = item.items;
                this.showActionMenusDialog = true;
            } else if(item.action == "func"){
                this[item.method]();
            } else {
                this.getForm(item.url)
            }
        },
        getOperationOutcomeExport(){
        },
        openLibraryDialog(data, action) {
            this.libParams = action.params;

            let url = "/library/getFilters?libType=" + this.libParams.libType;
            for (const param in this.libParams)
                url += "&params[" + param + "]=" + encodeURIComponent(this.libParams[param]);

            GenericDataService.getData(url).then((response) => {
                this.formLib = response.data.data;
                this.showLibraryDialog = true;
            });
        },
    },
}
</script>
<style lang="scss">
    #app-content {
        position:relative;
        #containerView {
            position:relative;
        }
    }
</style>
